define("discourse/plugins/automation/admin/components/fields/da-period-field", ["exports", "@glimmer/tracking", "@ember/component", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/runloop", "@ember-compat/tracked-built-ins", "discourse-i18n", "select-kit/components/combo-box", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/template-factory"], function (_exports, _tracking, _component, _helper, _modifier, _object, _runloop, _trackedBuiltIns, _discourseI18n, _comboBox, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PeriodField extends _daBaseField.default {
    static #_ = (() => dt7948.g(this.prototype, "interval", [_tracking.tracked], function () {
      return 1;
    }))();
    #interval = (() => (dt7948.i(this, "interval"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "frequency", [_tracking.tracked], function () {
      return null;
    }))();
    #frequency = (() => (dt7948.i(this, "frequency"), void 0))();
    constructor() {
      super(...arguments);
      (0, _runloop.next)(() => {
        if (!this.args.field.metadata.value) {
          this.args.field.metadata.value = new _trackedBuiltIns.TrackedObject({
            interval: 1,
            frequency: null
          });
        }
        this.interval = this.args.field.metadata.value.interval;
        this.frequency = this.args.field.metadata.value.frequency;
      });
    }
    get recurringLabel() {
      return _discourseI18n.default.t("discourse_automation.triggerables.recurring.every");
    }
    get replacedContent() {
      return (this.args.field?.extra?.content || []).map(r1 => {
        return {
          id: r1.id,
          name: _discourseI18n.default.t(r1.name)
        };
      });
    }
    mutInterval(event1) {
      this.args.field.metadata.value.interval = event1.target.value;
    }
    static #_3 = (() => dt7948.n(this.prototype, "mutInterval", [_object.action]))();
    mutFrequency(value1) {
      this.args.field.metadata.value.frequency = value1;
      this.frequency = value1;
    }
    static #_4 = (() => dt7948.n(this.prototype, "mutFrequency", [_object.action]))();
    static #_5 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="field period-field control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
    
          <div class="controls">
            {{this.recurringLabel}}
    
            <Input
              @type="number"
              defaultValue="1"
              @value={{this.interval}}
              disabled={{@field.isDisabled}}
              required={{@field.isRequired}}
              {{on "input" this.mutInterval}}
            />
    
            <ComboBox
              @value={{this.frequency}}
              @content={{this.replacedContent}}
              @onChange={{this.mutFrequency}}
              @options={{hash allowAny=false disabled=@field.isDisabled}}
              @required={{@field.isRequired}}
            />
    
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      
    */
    {
      "id": "A2t4iA0Y",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"field period-field control-group\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[1,[30,0,[\"recurringLabel\"]]],[1,\"\\n\\n        \"],[8,[32,1],[[24,\"defaultValue\",\"1\"],[16,\"disabled\",[30,2,[\"isDisabled\"]]],[16,\"required\",[30,2,[\"isRequired\"]]],[4,[32,2],[\"input\",[30,0,[\"mutInterval\"]]],null]],[[\"@type\",\"@value\"],[\"number\",[30,0,[\"interval\"]]]],null],[1,\"\\n\\n        \"],[8,[32,3],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\",\"@required\"],[[30,0,[\"frequency\"]],[30,0,[\"replacedContent\"]],[30,0,[\"mutFrequency\"]],[28,[32,4],null,[[\"allowAny\",\"disabled\"],[false,[30,2,[\"isDisabled\"]]]]],[30,2,[\"isRequired\"]]]],null],[1,\"\\n\\n        \"],[8,[32,5],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-period-field.js",
      "scope": () => [_daFieldLabel.default, _component.Input, _modifier.on, _comboBox.default, _helper.hash, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PeriodField;
});
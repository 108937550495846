define("discourse/plugins/automation/admin/components/fields/da-message-field", ["exports", "@ember/legacy-built-in-components", "@ember/object", "discourse/plugins/automation/admin/components/placeholders-list", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _legacyBuiltInComponents, _object, _placeholdersList, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MessageField extends _daBaseField.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field message-field">
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <div class="field-wrapper">
                <TextArea
                  @value={{@field.metadata.value}}
                  @input={{this.updateValue}}
                  @disabled={{@field.isDisabled}}
                />
    
                <DAFieldDescription @description={{@description}} />
    
                {{#if this.displayPlaceholders}}
                  <PlaceholdersList
                    @currentValue={{@field.metadata.value}}
                    @placeholders={{@placeholder}}
                    @onCopy={{this.test}}
                  />
                {{/if}}
              </div>
            </div>
          </div>
        </section>
      
    */
    {
      "id": "4KfbnVQy",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field message-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"field-wrapper\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@value\",\"@input\",\"@disabled\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"updateValue\"]],[30,2,[\"isDisabled\"]]]],null],[1,\"\\n            \"],[8,[32,2],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"displayPlaceholders\"]],[[[1,\"              \"],[8,[32,3],null,[[\"@currentValue\",\"@placeholders\",\"@onCopy\"],[[30,2,[\"metadata\",\"value\"]],[30,4],[30,0,[\"test\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\",\"@placeholder\"],false,[\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-message-field.js",
      "scope": () => [_daFieldLabel.default, _legacyBuiltInComponents.TextArea, _daFieldDescription.default, _placeholdersList.default],
      "isStrictMode": true
    }), this))();
    updateValue(event1) {
      this.mutValue(event1.target.value);
    }
    static #_2 = (() => dt7948.n(this.prototype, "updateValue", [_object.action]))();
  }
  _exports.default = MessageField;
});